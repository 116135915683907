import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from } from 'rxjs';
import {flatMap} from 'rxjs/operators';
import { BehaviorSubject,Subject } from 'rxjs';

export class Condomino {
    id : number;
  nome: string;
  apartamento: string;
  bloco: string;
}

@Injectable()
export class CondominoService {
    private URL = "";
    private token: String;

    public test$: BehaviorSubject<any>;

    @Output() change: EventEmitter<boolean> = new EventEmitter();

    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
        this.test$ = new BehaviorSubject("as");
    }

    getUnidades(){
        // print(this.hs.getHeaders())
        return this.http.get<Condomino[]>(this.URL + "/minhas-unidades",{headers:this.hs.getHeaders()});
    }

    all(id_condominio: number){
        return this.http.get<Condomino[]>(this.URL+'/condominio/'+id_condominio+'/condominos', {headers:this.hs.getHeaders()});
    }

   
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return from(result);
        };
    }

    setSelected(unidade: Condomino){
        localStorage.setItem("unidade", JSON.stringify(unidade));
        this.test$.next("que coisa");

        // this.messageSource.
        // this.change.emit(true);
    }
    
    getSelected(){
        return JSON.parse(localStorage.getItem("unidade"))
    }
}
