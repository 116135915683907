import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from, BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from "./users.service";

import {Foto} from './foto.service';

@Injectable()
export class GaleriaService {
    private URL = "";
    private token: String;

    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
    }

    upload(id: number, formData: FormData){
        return this.http.post(this.URL+"/galeria/"+id+"/upload", formData, {
            headers:this.hs.getHeaders(true),
        });

    }
}

export class Galeria{
    @Input() id: number;
    @Input() nome_galeria: String;
    @Input() id_capa: number;
    fotos: Foto[]
}