import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';

@Injectable()
export class UsersService {
    private URL = "";
    private token: String;
    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
    }

    login(data) {
        var body = new HttpParams()
            .set('email', data.email)
            .set('password', data.password);
        return this.http.post(this.URL + "login", body)
    }   

    details(){
        return this.http.get<User>(this.URL+"/details",{ headers: this.hs.getHeaders() })
    }

    getLogged(): User{
        return (new User()).deserialize(JSON.parse(window.localStorage.getItem('currentUser')))
    }

    update(data: User){
        return this.http.post(this.URL+"/user/update", data,{
            headers: this.hs.getHeaders()
        });
    }

    upload(id: number, formData: FormData){
        return this.http.post(this.URL+"/user/photo/"+id, formData, {
            headers:this.hs.getHeaders(true),
        });
    }

    updateStores(user: User){
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return from(result);
        };
    }


}

export class User {
    @Input() id : number;
    @Input() name: string;
    @Input() email: string;
    @Input() telefone: string;
    @Input() telefone_2: string;
    @Input() telefone_comercial: string;
    @Input() telefone_emergencias: string;
    @Input() site: string;
    @Input() facebook: string;
    @Input() instagram: string;
    @Input() photo: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
