import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from, BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from "./users.service";

import { Galeria } from "./galeria.service";

@Injectable()
export class ClassificadoService {
    private URL = "";
    private token: String;

    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
    }

    all(id_condomino: number){
        return this.http.get<Classificado[]>(this.URL+"/condomino/"+id_condomino+"/classificados", {headers: this.hs.getHeaders()})
    }

    post(classificado: Classificado){
        return this.http.post(this.URL+"/classificado", classificado, {headers: this.hs.getHeaders()});
    }

    upload(id: number, form: FormData){
        return this.http.post(this.URL+"/classificado/upload/"+id, form, {
            headers:this.hs.getHeaders(true),
        });
    }

    getGaleira(id){
        return this.http.get<ClassificadoGaleria>(this.URL+ "/classificado/"+id+"/galeria");
    }

    uploadGaleria(id: number, form: FormData){
        return this.http.post(this.URL+"/classificado/upload-galeria/"+id, form, {
            headers:this.hs.getHeaders(true),
        });
    }

    update(classificado: Classificado){
        return this.http.put(this.URL+"/classificado/"+classificado.id, classificado, {headers: this.hs.getHeaders()});
    }

    delete(id: number){
        return this.http.delete(this.URL+"/classificado/"+id, {headers: this.hs.getHeaders()});
    }

}

export class Classificado {
    @Input() id: number;
    @Input() titulo: String;
    @Input() descricao: String;
    @Input() valor: String;
    @Input() arquivo1: String;
    @Input() arquivo2: String;
    @Input() arquivo3: String;
    @Input() arquivo4: String;
    @Input() arquivo5: String;
    @Input() condomino_id: number;
    @Input() condominio_id: number;
    @Input() status: String;
    @Input() telefone1: String;
    @Input() telefone2: String;
    @Input() telefone3: String;
    @Input() email: String;
    galeria: ClassificadoGaleria
}

export class ClassificadoGaleria{
    @Input() id:                number;
    @Input() galeria_id:        number;
    @Input() classificado_id:   number;
    galeria: Galeria
}