import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from, BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from "./users.service";

@Injectable()
export class PetService {
    private URL = "";
    private token: String;

    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
    }

    all(id_condomino: number){
        return this.http.get<Pet[]>(this.URL+"/condomino/"+id_condomino+"/pets", {headers: this.hs.getHeaders()})
    }

    post(pet: Pet){
        return this.http.post(this.URL+"/pet", pet, {headers: this.hs.getHeaders()});
    }

    update(pet: Pet){
        return this.http.put(this.URL+"/pet/"+pet.id, pet, {headers: this.hs.getHeaders()});
    }

    delete(id: number){
        return this.http.delete(this.URL+"/pet/"+id, {headers: this.hs.getHeaders()});
    }

}

export class Pet {
    @Input() id: number;
    @Input() nome: string;
    @Input() apelido: string;
    @Input() especie: string;
    @Input() data_nascimento: string;
    @Input() genero: string;
    @Input() raca: string;
    @Input() Cor: string;
    @Input() faixa_peso: string;
    @Input() sobre: string;
    @Input() pv1: string;
    @Input() pv1adate: string;
    @Input() pv2: string;
    @Input() pv2adate: string;
    @Input() pv3: string;
    @Input() pv3adate: string;
    @Input() tc1: string;
    @Input() tc1adate: string;
    @Input() tc2: string;
    @Input() tc2adate: string;
    @Input() gta1: string;
    @Input() gta1adate: string;
    @Input() gta2: string;
    @Input() gta2adate: string;
    @Input() raiva1: string;
    @Input() raiva1adate: string;
    @Input() created: string;
    @Input() modified: string;
    @Input() id_condomino: number;
    @Input() id_condominio: number;
}