import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from, BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from "./users.service";

import {Foto} from './foto.service';

@Injectable()
export class MedidorService {
    private URL = "";
    private token: String;

    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
    }

    getByCondomino(id_condomino: number, tipo: number){
        return this.http.get<Medidor[]>(this.URL+"/condomino/medidores/"+id_condomino+"/"+tipo);
    }
}

export class Medidor{
    @Input() id_condomino: number;
    @Input() tipo: number;
    @Input() numero_serie: string;
    @Input() descricao_motivo: string;
    @Input() status: number;
    @Input() descricao: string;
    @Input() leitura: number;
    @Input() data: string;
    @Input() medicao: string;
    @Input() status_corte: number;
    @Input() ultima_leitura_corte: string;
    @Input() id_old: number;
    @Input() id_new: number;
}