import { Injectable, Input } from '@angular/core';

@Injectable()
export class GeneroService {
    public generos = [
        "Feminino",
        "Masculino",
        "Outro"
    ];
}
