import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from, BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from "./users.service";


@Injectable()
export class AvisoService {
    private URL = "";
    private token: String;

    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
    }

    byCondominio(id_condominio: number){
        return this.http.get<Aviso[]>(this.URL+"/condominio/"+id_condominio+"/avisos",{headers: this.hs.getHeaders()})
    }

    byCondomino(id_condominio: number, id_condomino: number){
        return this.http.get<Aviso[]>(this.URL+"/condominio/"+id_condominio+"/condomino/"+id_condomino+"/avisos",{headers: this.hs.getHeaders()})
    }

    post(aviso: Aviso){
        return this.http.post(this.URL+"/aviso/"+aviso.condominio_id, aviso, {headers: this.hs.getHeaders()});
    }

    update(aviso: Aviso){
        return this.http.put(this.URL+"/aviso/"+aviso.id, aviso, {headers: this.hs.getHeaders()});
    }

    delete(id: number){
        return this.http.delete(this.URL+"/aviso/"+id, {headers: this.hs.getHeaders()});
    }

    permissions(id: number){
        return this.http.get<any>(this.URL+"/avisos/permissions/"+id, {headers: this.hs.getHeaders()})
    }

    upload(id: number, form: FormData){
        return this.http.post(this.URL+"/aviso/"+id+"/upload", form, {
            headers:this.hs.getHeaders(true),
        });
    }

    updateAnexo(id, data){
        return this.http.put(this.URL+"/anexo-aviso/"+id, data, {headers:this.hs.getHeaders()});
    }

    deleteAnexo(id){
        return this.http.delete(this.URL+"/anexo-aviso/"+id, {headers:this.hs.getHeaders()});
    }

    anexos(id){
        return this.http.get<AnexoAviso[]>(this.URL+"/aviso/"+id+"/anexos", {headers:this.hs.getHeaders()})
    }

}

export class Aviso{
    id:             number;
    created:        string;
    modified:       string;
    excluido:       boolean;
    titulo:         string;
    descricao:      string;
    status:         number;
    arquivo1:       string;
    arquivo2:       string;
    arquivo3:       string;
    condominio_id:  number;
    condomino_id:   number;
    tipo:           number;
    notificacao:    string;
    funcionario_id: number;
    portaria:       any;
    enviar_para_condomino_id:   number;
    responsavel:                Responsavel;
    para:                       Para;
    anexos:                     AnexoAviso[];
}

export class Responsavel{
    id: number;
    nome: string
}

export class Para{
    id: number;
    nome: string
}

export class AnexoAviso{
    id: number;
    aviso_id: number;
    descricao: string;
    arquivo: string;
    tipo: string;
}