// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
					{
						title: 'Ações',
						root: true,
						icon: 'flaticon-add',
						toggle: 'click',
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'Reservar área comum',
									page: '/reservar',
									icon: 'flaticon-time-3',
									
								},
								
							]
						}
					},
					{
						title: 'Reports',
						root: true,
						icon: 'flaticon-line-graph',
						toggle: 'click',
						translate: 'MENU.REPORTS',
						submenu: {
							type: 'mega',
							width: '1000px',
							alignment: 'left',
							columns: [
								{
									heading: {
										heading: true,
										title: 'Finance Reports',
									},
									items: [
										{
											title: 'Annual Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-map',
										},
										{
											title: 'HR Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-user',
										},
										{
											title: 'IPO Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-clipboard',
										},
										{
											title: 'Finance Margins',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-graphic-1',
										},
										{
											title: 'Revenue Reports',
											tooltip: 'Non functional dummy link',
											icon: 'flaticon-graphic-2',
										}
									]
								},
								{
									bullet: 'line',
									heading: {
										heading: true,
										title: 'Project Reports',
									},
									items: [
										{
											title: 'Coca Cola CRM',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title:
												'Delta Airlines Booking Site',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Malibu Accounting',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Vineseed Website Rewamp',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Zircon Mobile App',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Mercury CMS',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								},
								{
									bullet: 'dot',
									heading: {
										heading: true,
										title: 'HR Reports',
									},
									items: [
										{
											title: 'Staff Directory',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Client Directory',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Salary Reports',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Staff Payslips',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Corporate Expenses',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Project Expenses',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								},
								{
									heading: {
										heading: true,
										title: 'Reporting Apps',
										icon: '',
									},
									items: [
										{
											title: 'Report Adjusments',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Sources & Mediums',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Reporting Settings',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Conversions',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Report Flows',
											tooltip: 'Non functional dummy link',
											icon: '',
										},
										{
											title: 'Audit & Logs',
											tooltip: 'Non functional dummy link',
											icon: '',
										}
									]
								}
							]
						}
					},
					{
						title: 'Apps',
						root: true,
						icon: 'flaticon-paper-plane',
						toggle: 'click',
						translate: 'MENU.APPS',
						badge: {
							type: 'm-badge--brand m-badge--wide',
							value: 'new',
							translate: 'MENU.NEW',
						},
						submenu: {
							type: 'classic',
							alignment: 'left',
							items: [
								{
									title: 'eCommerce',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-business',
									submenu: {
										type: 'classic',
										alignment: 'right',
										items: [
											{
												title: 'Customers',
												page: '/ecommerce/customers',
												icon: 'flaticon-users',
											},
											{
												title: 'Orders',
												page: '/ecommerce/orders',
												icon: 'flaticon-interface-1',
											},
											{
												title: 'Products',
												page: '/ecommerce/products',
												icon: 'flaticon-list-1',
											}
										]
									}
								},
								{
									title: 'Audience',
									page: '/crud/datatable_v1',
									icon: 'flaticon-computer',
									submenu: {
										type: 'classic',
										alignment: 'right',
										items: [
											{
												title: 'Active Users',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-users',
											},
											{
												title: 'User Explorer',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-interface-1',
											},
											{
												title: 'Users Flows',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-lifebuoy',
											},
											{
												title: 'Market Segments',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-graphic-1',
											},
											{
												title: 'User Reports',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-graphic',
											}
										]
									}
								},
								{
									title: 'Marketing',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-map',
								},
								{
									title: 'Campaigns',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-graphic-2',
									badge: {
										type: 'm-badge--success',
										value: '3'
									}
								},
								{
									title: 'Cloud Manager',
									tooltip: 'Non functional dummy link',
									icon: 'flaticon-infinity',
									submenu: {
										type: 'classic',
										alignment: 'left',
										items: [
											{
												title: 'File Upload',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-add',
												badge: {
													type: 'm-badge--danger',
													value: '3'
												}
											},
											{
												title: 'File Attributes',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-signs-1',
											},
											{
												title: 'Folders',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-folder',
											},
											{
												title: 'System Settings',
												tooltip: 'Non functional dummy link',
												icon: 'flaticon-cogwheel-2',
											}
										]
									}
								}
							]
						}
					}
				]
			},
			aside: {
				self: {},
				items: [
					{
						title: 'Área de Trabalho',
						desc: 'Um dashboard do seu condomínio',
						root: true,
						icon: 'flaticon-line-graph',
						page: '/',
						badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Perfil',
						desc: 'Página de perfil do usuário',
						root: true,
						icon: 'flaticon-user',
						page: '/perfil',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Meu consumo',
						desc: 'Relatório e gráficos de consumo',
						root: true,
						icon: 'flaticon-graph',
						page: '/consumo',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Avisos',
						desc: 'Avisos enviados pelo síndico',
						root: true,
						icon: 'flaticon-alert',
						page: '/avisos',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Reserva de Área Comum',
						desc: 'Consulte e realize reservas',
						root: true,
						icon: 'flaticon-time-3',
						page: '/reservas',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					// {section: 'Condomínio'},
					{
						title: 'Condomínio',
						root: true,
						bullet: 'dot',
						icon: 'flaticon-home',
						submenu: [
							{
								title: 'Agenda',
								icon: 'flaticon-event-calendar-symbol',
								bullet: 'dot',
								page: '/agenda',
							},
							{
								title: 'Almoxarifado',
								icon: 'flaticon-box',
								bullet: 'dot',
								page: '/almoxarifado',
							},
							{
								title: 'Áreas comuns',
								icon: 'flaticon-buildings',
								bullet: 'dot',
								page: '/areas-comuns',
							},
							{
								title: 'Assembléia Virtual',
								icon: 'flaticon-calendar-1 ',
								bullet: 'dot',
								page: '/assembleia-virtual',
							},
							{
								title: 'Bens e manutenção',
								icon: 'flaticon-settings',
								bullet: 'dot',
								page: '/bens',
							},
							{
								title: 'Classificados',
								icon: 'flaticon-price-tag',
								bullet: 'dot',
								page: '/classificados',
							},
							{
								title: 'Condôminos',
								icon: 'flaticon-users',
								bullet: 'dot',
								page: '/condominos',
							},
							
							{
								title: 'Contatos úteis',
								icon: 'flaticon-businesswoman',
								bullet: 'dot',
								page: '/contatos-uteis',
							},
							{
								title: 'Controle de Advertências',
								icon: 'flaticon-circle',
								bullet: 'dot',
								page: '/advertencias',
							},
							{
								title: 'Enquetes e Pesquisas',
								icon: 'flaticon-list-2',
								bullet: 'dot',
								page: '/enquetes',
							},
							{
								title: 'Fotos',
								icon: 'flaticon-tabs',
								bullet: 'dot',
								page: '/fotos',
							},
							{
								title: 'Funcionários',
								icon: 'flaticon-map',
								bullet: 'dot',
								page: '/funcionarios',
							},
							{
								title: 'Grupo Gestor',
								icon: 'flaticon-network',
								bullet: 'dot',
								page: '/grupo-gestor',
							},
							{
								title: 'Livro de ocorrências',
								icon: 'flaticon-exclamation',
								bullet: 'dot',
								page: '/livro-ocorrencias',
							},
							{
								title: 'Patrimônio',
								icon: 'flaticon-tool',
								bullet: 'dot',
								page: '/patrimonio',
							},
							
						]
					},
					{
						title: 'Fornecedores',
						desc: 'Encontre empresas e serviços',
						root: true,
						icon: 'flaticon-bag',
						page: '/fornecedores',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Fale com o condomínio',
						desc: 'Chat',
						root: true,
						icon: 'flaticon-chat',
						page: '/fale-com-o-condominio',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Fornecedores',
						desc: 'Encontre empresas e serviços',
						root: true,
						icon: 'flaticon-bag',
						page: '/fornecedores',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Documentos',
						desc: 'Documentos e pasta de documentos',
						root: true,
						icon: 'flaticon-file-2',
						page: '/documentos',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Notícias',
						desc: 'Notícias sobre a vida em condomínios',
						root: true,
						icon: 'flaticon-interface-2 ',
						page: '/noticias',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Leis e Modelos',
						desc: 'Leis e Modelos de Documentos',
						root: true,
						icon: 'flaticon-doc ',
						page: '/leis-modelos',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
					{
						title: 'Relatórios',
						desc: 'Acesse e imprima os relatórios do sistema referentes à sua unidade e ao seu condomínio',
						root: true,
						icon: 'flaticon-interface-10 ',
						page: '/relatorios',
						// badge: {type: 'm-badge--danger', value: '2'},
					},
				]
			}
		};
	}
}
