import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError, Observable, from, BehaviorSubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from "./users.service";

@Injectable()
export class FotoService {
    private URL = "";
    private token: String;

    constructor(private http: HttpClient, private hs: HttpService, ) {
        this.URL = hs.getAPI();
        this.token = localStorage.getItem('accessToken')
    }

    remove(id: number){
        return this.http.delete(this.URL+"/galeria/foto/"+id, {headers: this.hs.getHeaders()})
    }
}

export class Foto{
    @Input() id: number;
    @Input() galeria_id: number;
    @Input() descricao: String;
    @Input() foto: String;
}