import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpService {

    // public apiSite = "http://localhost:8000/";  
    public apiSite = "https://api.techmetria.com.br/";

    constructor(private http: HttpClient) {

    }

    getAPI() {
        return this.apiSite+"api";
    }

    getHeaders(file = false) {
        let headers = {
            'Authorization': 'Bearer '+localStorage.getItem('accessToken')
        }

        if(!file){
            headers['Content-Type'] = 'application/json';
        }

        return new HttpHeaders(headers);
    }

    
    
}
