import { Injectable, Input } from '@angular/core';

@Injectable()
export class FaixaPesoService {
    public faixas = [
        "Menos de 1 kg",
        "de 2 a 5 kg",
        "de 10 a 20 kg",
        "de 20 a 40 kg",
        "Acima de 40 kg"
    ];
}
