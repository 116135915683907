import { Injectable, Input } from '@angular/core';

@Injectable()
export class ServicoService {
    public servicos = [
        "Empregada Doméstica",
        "Motorista",
        "Diarista",
        "Manutenção",
        "Outros"
    ]
}
