import { Injectable, Input } from '@angular/core';

@Injectable()
export class EspecieService {
    public species = [
        "Cachorro",
        "Equino",
        "Gato",
        "Inseto",
        "Outros",
        "Peixe",
        "Réptil",
        "Suíno",
        "Outros"
    ]
}
